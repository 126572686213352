$(function(){

    const cookieConsentModal = document.querySelector('.cookie-consent-modal');
    const acceptBtn = document.querySelector('.cookie-consent-accept');

    if (!document.cookie.includes('cookie_consent=true')) {
        cookieConsentModal.style.display = 'flex';
    }

    acceptBtn.addEventListener('click', () => {
        // Сохраняем куку, чтобы пользователь не видел модальное окно снова
        document.cookie = 'cookie_consent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/';

        // Скрываем модальное окно
        cookieConsentModal.style.display = 'none';
    });


	$('#openFeedback').click(function(){
// 		$('#feedbackWindow .checkboxes-window__row').show();
	});

	$('#openFeedback2').click(function(){
		if ($('.mobileMenu').hasClass('active')){
			$('.mobileMenu').removeClass('active');
			$('.header-wrapper__nav').removeClass('active');
			$('body').removeClass('lock-scroll');
		}

		$('#feedbackWindow #closeWindow').click(function(){
// 			$('#feedbackWindow .checkboxes-window__row').show();
		});

// 		$('#feedbackWindow .checkboxes-window__row').hide();
		$('#feedbackWindow').addClass('active');

		return false;
	});
});
