const INPUTS_ERRORS = {
    EMPTY: 'Поле должно быть заполнено!',
    MIN_LENGTH: 'Нужно указать большее количество символов!',
    PHONE: 'В поле не правильно указан номер телефона!',
};

let feedbackWindow = document.querySelector('#feedbackWindow');
let sendFormButton = document.querySelector('#sendForm');
let name = document.querySelector('#name');
let phone = document.querySelector('#phone');
let telegram = document.querySelector('#telegramRadio');
let whatsapp = document.querySelector('#whatsappRadio');

function closeFeedbackWindow() {
    feedbackWindow.classList.remove('active');
}

function inputsHelpers(inputLength) {
    let result = {
        errorMessage: '',
        valid: Boolean,
    };

    if (inputLength === 0) {
        result = {
            errorMessage: INPUTS_ERRORS.EMPTY,
            valid: false,
        }
    } else if (inputLength < 2) {
        result = {
            errorMessage: INPUTS_ERRORS.MIN_LENGTH,
            valid: false,
        }
    } else {
        result = {
            errorMessage: null,
            valid: true,
        }
    }

    return result;
}

function nameIsValid() {
    let errorName = document.getElementById('nameError');

    if (inputsHelpers(name.value.length).valid) {
        name.classList.remove('has-error');
        return true;
    } else {
        name.classList.add('has-error');
        errorName.innerHTML = inputsHelpers(name.value.length).errorMessage;
        return false;
    }
}

function phoneIsValid() {
    let errorPhone = document.getElementById('phoneError');
    let phonePattern = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/

    if (phonePattern.test(phone.value) && phone.value.length == 11) {
        phone.classList.remove('has-error');
        errorPhone.innerHTML = null;
        return true;
    } else if (phone.value.length === 0) {
        phone.classList.add('has-error');
        errorPhone.innerHTML = 'Поле должно быть заполнено!'
        return false;
    } else if (phone.value.length < 11) {
        phone.classList.add('has-error');
        errorPhone.innerHTML = 'Номер телефона меньше 11 символов!'
        return false;
    } else {
        phone.classList.add('has-error');
        errorPhone.innerHTML = 'Неверный формат номера телефона!'
        return false;
    }
}

function radioIsValid() {
    let radioError = document.getElementById('radioError');

    if (telegram.checked || whatsapp.checked) {
        if (radioError.classList.contains('has-error')) {
            radioError.classList.remove('has-error')
        }
        return true;
    } else {
        radioError.classList.add('has-error');
        return false;
    }
}

function checkFormBeforeSend() {
    let isNameValid = nameIsValid();
    let isPhoneValid = phoneIsValid();
    let isRadioValid = radioIsValid();

    return isNameValid && isPhoneValid && isRadioValid;
}

function resetFormFieldsAfterSend() {
    name.value = '';
    phone.value = '';

    if (telegram.checked) {
        telegram.checked = !telegram.checked;
    } else {
        whatsapp.checked = !whatsapp.checked;
    }

}

function sendForm() {
    if (checkFormBeforeSend()) {
        let modalResult = document.getElementById('modalResult');

        feedbackWindow.classList.remove('active');

        $.ajax({
            url: '/main/send-form',
            data: JSON.stringify({
                name: name.value,
                phone: phone.value,
                messenger: telegram.checked ? 'telegram' : 'whatsapp',
            }),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            type: 'POST',
            success: function(data) {
                if (data.success == 1) {
                    setTimeout(() => {
                        resetFormFieldsAfterSend();
                        modalResult.classList.add('active');
                    }, 0);
                    setTimeout(() => {
                        modalResult.classList.remove('active');
                        ym(86720935, 'reachGoal', 'form');
                        gtag('event', 'form', { 'event_name': 'form' });
                    }, 3000);
                }
            },
            error: function() {
                // toastr['error']('Что-то пошло не так!')
            },
        })
    }
}

sendFormButton.addEventListener('click', sendForm);