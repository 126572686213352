let modalBackground = document.getElementsByClassName('feedback-background')[0];
let mobileMenu = document.getElementsByClassName('header-wrapper__nav')[0];
let feedbackWindow = document.getElementById('feedbackWindow');
let openFeedbackModalMobile = document.getElementById('openFeedbackModalMobile');
let openFeedbackModalWindow1 = document.getElementById('openFeedbackModalWindow1');
let openMobFeedback = document.getElementById('openMobFeedback');
let openFeedback = document.getElementById('openFeedback');
let closeFeedback = document.getElementById('closeWindow');
let burger = document.getElementById('mobileMenu');
let page = document.querySelector('body');
let feedbackModalTrigger = document.querySelectorAll('.feedback-modal');


function openFeedbackWindow() {
    if (burger.classList.contains('active')) {
        burger.classList.remove('active');
        mobileMenu.classList.remove('active');
        page.classList.remove('lock-scroll');
    }

    feedbackWindow.classList.add('active');
}

function closeFeedbackWindow() {
    feedbackWindow.classList.remove('active');
}

feedbackModalTrigger.forEach(trigger => {
    trigger.addEventListener('click', () => openFeedbackWindow());
});

openFeedback.addEventListener('click', openFeedbackWindow);
closeFeedback.addEventListener('click', closeFeedbackWindow);
openMobFeedback.addEventListener('click', openFeedbackWindow);
modalBackground.addEventListener('click', closeFeedbackWindow);
openFeedbackModalMobile.addEventListener('click', openFeedbackWindow);
openFeedbackModalWindow1.addEventListener('click', openFeedbackWindow);