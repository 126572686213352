$('.main-clients__slider').slick({
    slidesToShow: 3,
    infinite: true,
    draggable: true,
    dots: false,
    swipeToSlide: true,
    arrows: false,
    autoplay: true,
    speed: 1500,
    LazyLoad: 'ondemand',
    responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                centerMode: true,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
});