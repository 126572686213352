const anchors = document.querySelectorAll('.header-wrapper__nav-menu__link');
let navWrapper = document.querySelector('.header-wrapper__nav');
let burger = document.querySelector('.header-wrapper__burger');
let body = document.querySelector('body');

for (let anchor of anchors) {
    anchor.addEventListener('click', function(e) {
        e.preventDefault()

        if (navWrapper.classList.contains('active') && burger.classList.contains('active') && body.classList.contains('lock-scroll')) {
            navWrapper.classList.remove('active');
            burger.classList.remove('active');
            body.classList.remove('lock-scroll');
        }

        const blockID = anchor.getAttribute('href').substr(1)

        document.getElementById(blockID).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    })
}