$(function(){
	$('#openFeedback').click(function(){
// 		$('#feedbackWindow .checkboxes-window__row').show();
	});
	
	$('#openFeedback2').click(function(){
		if ($('.mobileMenu').hasClass('active')){
			$('.mobileMenu').removeClass('active');
			$('.header-wrapper__nav').removeClass('active');
			$('body').removeClass('lock-scroll');
		}

		$('#feedbackWindow #closeWindow').click(function(){
// 			$('#feedbackWindow .checkboxes-window__row').show();
		});
		
// 		$('#feedbackWindow .checkboxes-window__row').hide();
		$('#feedbackWindow').addClass('active');
		
		return false;
	});
});
