let burgerIcon = document.getElementById('mobileMenu');

function activeMobileMenu() {
  let page = document.querySelector('body');
  let mobileMenu = document.getElementsByClassName('header-wrapper__nav')[0];

  burgerIcon.classList.toggle('active');
  mobileMenu.classList.toggle('active');
  page.classList.toggle('lock-scroll');
}

burgerIcon.addEventListener('click', activeMobileMenu);

